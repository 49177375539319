import { Route, Routes } from 'react-router-dom'
import LogoutPage from '../auth/LogoutPage'
import ListFormulasPage from '../formulas/ListFormulasPage'
import ListGenesisFormulasPage from '../genesis/ListGenesisFormulasPage'
import ListUsersPage from '../users/ListUsersPage'
import UserFormPage from '../users/UserFormPage'

import HomePage from '../home/HomePage'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import AdminPage from '../admin/AdminPage'
// import { FeatureFlags } from '../config/config'

export default function Body(props = {}) {
  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <div className="content-wrapper">
          <section className="content body">
            <div className="row">
              <Routes>
                <Route exact={false} path="/" element={<HomePage />} />
                <Route exact={true} path="/logout" element={<LogoutPage />} />
                {roleCtx.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <>
                    <Route exact={true} path="/users/list" element={<ListUsersPage />} />
                    <Route exact={true} path="/users/edit/:koId" element={<UserFormPage />} />

                  </>}
                  <Route exact={true} path="/formulas" element={<ListFormulasPage />} />
                  <Route exact={true} path="/genesis" element={<ListGenesisFormulasPage />} />
                {roleCtx.hasRole([ROLES.ADMIN]) && <>
                  <Route exact={true} path="/app/admin" element={<AdminPage />} />
                </>}
              </Routes>
            </div>
          </section>
        </div>
      )}
    </RoleContext.Consumer>
  )
}
