import { default as React, useState, useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Science } from '@mui/icons-material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListFormulas from './ListFormulas'

import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

function TabPanel (props) {
  const { children, selectedTab, thisTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== thisTab}
      id={`tabpanel-${thisTab}`}
      aria-labelledby={`tab-${thisTab}`}
      {...other}
    >
      {selectedTab === thisTab && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  thisTab: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default function ListFormulasPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const roleDetails = useContext(RoleContext)
  const [selectedTab, setSelectedTab] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams({ view: 'open', page: '1'})

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><Science />&nbsp;Formulas</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const tabChange = (event, toTab) => {
    setSearchParams({view: toTab === 1 ? 'closed' : 'open'})
  }

  useEffect(() => {
    setSelectedTab(searchParams.get('view') === 'closed' ? 1 : 0)
  }, [searchParams])

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} 
      commandBar={
        <ListViewCommandBar 
          showFilters={true}
          toggleFilters={toggleFilters} 
          showAdd={false}              
        />
      }>
      <Paper>
        <TabPanel selectedTab={selectedTab} thisTab={0}>
          <ListFormulas showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} rowsPerPage={10} />
        </TabPanel>
      </Paper>
    </Page>
  )
}