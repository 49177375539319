import { domain, azureClientId, azureTenantId, apiKey, stage, featureFlags } from './config-current'

export const Stage = stage
export const FeatureFlags = featureFlags
export const ApiGateway =  {
  apiKey,
  domain,
  authorizations: `${domain}/authorizations`,
  authorization: `${domain}/authorization`,
  employee: `${domain}/employees`,
  profile: `${domain}/profile`,
  user: `${domain}/users`,
  fitnessIndexes: `${domain}/fitness-indexes`,
  genesis: `${domain}/genesis`,
  auth: ''
}

export const AzureAdClient = {
  info: {
    clientId: `${azureClientId}`,
    tenantId: `${azureTenantId}`
  },
  auth: {
    clientId: `${azureClientId}`,
    authority: `https://login.microsoftonline.com/${azureTenantId}`
  }
}
