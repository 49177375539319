import { useContext } from 'react'
import Menu from './Menu'
import { Dangerous as DangerousIcon, People as PeopleIcon, Science as ScienceIcon, Restaurant as RestaurantIcon } from '@mui/icons-material'
import MenuItem from './MenuItem'
import MenuDivider from './MenuDivider'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import UserContext from '../auth/UserContext'
import { FeatureFlags } from '../config/config'

export default function SidebarMenu(props = {}) {
  window.scrollTo(0, 0)
  const roleDetails = useContext(RoleContext)
  const userDetails = useContext(UserContext)

  return (
    <Menu>
      <MenuItem href="/formulas" title="Formulas" icon={<ScienceIcon />} />
      <MenuItem href="/genesis" title="Genesis" icon={<RestaurantIcon />} />
      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <>
          <MenuDivider />
          <MenuItem href="/users/list/" title="User Management" icon={<PeopleIcon />} />
        </>
      }
      {roleDetails.hasRole([ROLES.ADMIN]) && <MenuItem href="/app/admin" title="Application Admin" icon={<DangerousIcon />} />}
    </Menu>
  )
}
