import { default as React, useState, useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Restaurant as RestaurantIcon } from '@mui/icons-material'
import ListGenesisFormulas from './ListGenesisFormulas'
import ListViewCommandBar from '../commandBar/ListViewCommandBar'
import Page from '../layout/Page'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import { Paper } from '@mui/material';


export default function ListGenesisFormulasPage (props = {}) {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const roleDetails = useContext(RoleContext)
  const [selectedTab, setSelectedTab] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams({ view: 'open', page: '1'})

  const startLoading = () => {
    setIsLoading(true)
  }

  const stopLoading = () => {
    setIsLoading(false)
  }

  const breadcrumb = <span><RestaurantIcon/> Genesis Recipies</span>

  const [showFilters, setShowFilters] = useState(false)
  const toggleFilters = () => {
    setShowFilters(!showFilters)
  }

  const tabChange = (event, toTab) => {
    setSearchParams({view: toTab === 1 ? 'closed' : 'open'})
  }

  useEffect(() => {
    setSelectedTab(searchParams.get('view') === 'closed' ? 1 : 0)
  }, [searchParams])

  return (
    <Page { ...props} breadcrumb={breadcrumb} isLoading={isLoading} 
      commandBar={
        <ListViewCommandBar 
          showFilters={true}
          toggleFilters={toggleFilters} 
          showAdd={false}              
        />
      }>
      <Paper>
        <ListGenesisFormulas showFilters={showFilters} startLoading={startLoading} stopLoading={stopLoading} rowsPerPage={10} />
      </Paper>
    </Page>
  )
}