import { useContext, useEffect, useMemo, useState } from 'react'
import { useIsMounted } from '../utils/ReactUtils.js'
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table'
import { Visibility as VisibilityIcon } from '@mui/icons-material'
import { Box, Grid, Paper, IconButton, Button} from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import RoleContext from '../auth/RoleContext.js'
import { getRequestConfig } from '../auth/auth.js'
import { ApiGateway } from '../config/config.js';
import dayjs from 'dayjs';

export default function ListGenesisFormulas(props = {searchFilter: undefined, rowsPerPage: 100, pageIndex: 0 }) {
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [dataRows, setDataRows] = useState([])
  
  const roleDetails = useContext(RoleContext)

  const listColumns = useMemo(() => [
    {
      header: 'Primary Key',
      accessor: 'primaryKey',
      
    }, {
      header: () => <span>Item Name <i className= "fa fa-info-circle icon-bg info-icon" title="Use the '%' character as a wildcard in your search!"/></span>,
      accessor: 'description',
      width: 160
    }, {
      header: 'User Code',
      accessor: 'userCode'
    }/*, {
      header: 'Supplier',
      accessor: 'supplier.supplierName',
      Cell: (row) => {
        return <span>{row.value}</span>
      },
      Filter: ({filter, onChange}) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{width: '100%'}}
          value={filter ? filter.value : ''}
        >
          <option value='' />
          <option value='GENESIS'>Genesis</option>
          <option value='PICASSO'>Picasso</option>
          <option value='<None>'>&lt;None&gt;</option>
        </select>
      )
    }, {
      header: 'Group',
      accessor: 'group.groupKey',
      Cell: (row) => {
        let group = row.original.group || {}
        return <span>{group.name}</span>
      },
      Filter: ({filter, onChange}) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{width: '100%'}}
          value={filter ? filter.value : ''}
        >
          <option value=''>Any</option>
          <option value='1358656146'>Retail</option>
          <option value='794365709'>Food Service</option>
        </select>
      )
    }, {
      header: 'Modify Date',
      accessor: 'modifyDate',
      Cell: (row) => {
        let modifyDate = row.original.modifyDate 
        if(modifyDate){
          return <span>{dayjs(modifyDate).format('MM/DD/YYYY')}</span>
        } 
        return <span></span>
      }
    }, {
      header: '',
      headerClassName: 'action-icon',
      className: 'action-icon',
      sortable: false,
      filterable: false,
      Cell: (rowInfo) => {
        let navigate = () => {
        this.props.history.push({
            pathname: `/sra/genesis/recipes/${rowInfo.row.primaryKey}`
        });
        }
        return <Button onClick={navigate} bsSize="small"><VisibilityIcon/></Button>
      }
    }*/
  ])

  const getData = async() => {
    props.startLoading()

    const requestConfig = await getRequestConfig()
    console.log('requestConfig: ', requestConfig)
    const basePath = 'https://api.dev.tis.coke.com/genesis' //ApiGateway.genesis
    const response = await axios.get(`${basePath}/base-food-names`, requestConfig)

    console.log('response: ', response)

    setDataRows(response?.data?.data ?? [])
      
    props.stopLoading()
  }

  

  useEffect(() => {
    if (isMounted()) {
      getData()
    }
  }, [])

  const table = useMaterialReactTable({
    layoutMode: 'grid',
    columnFilterModeOptions: [
      'fuzzy',
      'greaterThan',
      'lessThan'
    ],
    columns: listColumns,
    data: dataRows,
    enableColumnActions: false,
    enableColumnFilters: props.showFilters,
    initialState: {
      showColumnFilters: true,
      pagination: {
        pageIndex: props.pageIndex ?? 0,
        pageSize: props.rowsPerPage ?? 100
      }
    },
    enablePagination: true,
    enableSorting: true,
    enableBottomToolbar: true,
    enableEditing: false,
    muiTableContainerProps: {
      sx: { minHeight: 300 }
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '.82rem',
        padding: '4px !important'
      }
    },
    renderTopToolbar: false,
  });


  return (
    <div className='App'>
      <Paper sx={{ backgroundColor: 'transparent', p: '0 0 3em 0', border: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '98%', margin: '1em auto'}}>
        <Grid container rowSpacing={2} >
          <Grid item xs={true} md={true}>            
            <MaterialReactTable table={table} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )

}